.oblatdetaljer {
  max-width: none;
  width: 80vw;
}
.oblatdetaljer-section {
  margin-top: 10px;
}
.oblathistorikk-section {
  padding: 20px;
  border: solid 1px gray;
  border-radius: 7px;
  background-color: antiquewhite;
}

.oblathistorikk-section td + td,
.oblathistorikk-section th + th {
  padding: 0 15px 0 15px;
}

.endre-oblat-section > div {
  padding: 20px;
  border: solid 1px gray;
  border-radius: 7px;
}
.legg-til-kjoretoy-form {
  padding: 20px;
  border: solid 1px gray;
  border-radius: 7px;
  margin: 10px 0px;
}
.legg-til-kjoretoy-form > button {
  margin: 5px;
}

.legg-til-kjoretoy-form > div > strong {
  margin-right: 20px;
  min-width: 200px;
}
.legg-til-kjoretoy-form > div > input {
  width: 300px;
  border-radius: 5px;
  padding: 5px;
}
.endre-oblat-section > div > div > select {
  width: 300px;
  padding: 5px;
}
.endre-oblat-section-velg-Kjoretoy {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.endre-oblat-section-velg-Kjoretoy > div {
  width: 100%;
}
.endre-oblat-section-velg-Kjoretoy > div > textarea,
.oblatdetaljer-section > textarea {
  border-radius: 5px;
  width: 100%;
}
.text-input {
  display: block;
  border-radius: 5px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  padding: 5px 10px;
}
.text-input-section {
  display: block;
}

.oblat-tabell {
  background-color: antiquewhite;
}

.oblataction-sections {
  display: flex;
  flex-direction: column;
}

.textarea-section {
  display: flex;
  flex-direction: column;
}

.partial-refund-button {
  display: flex;
  flex-direction: column;
}

.flexcolumn > button {
  margin-top: 5px;
  margin-bottom: 10px;
}
#fullRefund-button {
  margin-top: 30px;
}

.flexcolumn {
  display: flex;
  flex-direction: column;
}
.date-info {
  display: flex;
  flex-direction: row;
}

.tooltipcontainer {
  position: relative;
}

.tooltipinfo {
  visibility: hidden;
  width: 420px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 15px;
  border-radius: 6px;
  right: 0%;
  bottom: 130%;
  position: absolute;
}

.tooltipcontainer:hover .tooltipinfo {
  visibility: visible;
}

#infoIcon {
  margin-left: 5px;
  fill: black;
  color: black;
  width: 32;
  height: 32;
}

.date-calculate > button {
  margin-top: 5px;
  margin-bottom: 15px;
}
