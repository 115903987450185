.addBruker {
  margin: 25px;
}
.addBruker .form-group,
.addBruker button {
  margin: 25px;
  padding-left: 10px;
  font-size: 15px;
  width: 320px;
}

.add-user-section {
  display: flex;
  flex-direction: column;
  justify-content: left;

  padding: 15px 25px;
  color: #4d4d4d;
  background: #d8d8d8;
}
