.kjoretoy-page {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  justify-content: top;
  align-items: center;
}
.kjoretoy-title {
  justify-content: stretch;
  width: 100%;
  padding: 15px 25px;
  line-height: 30px;
  font-size: 25px;
  font-weight: 300;
  color: #4d4d4d;
  text-align: center;
  background: #d8d8d8;
}

.kjoretoy-add-form {
  padding: 15px;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #c4c4c4;
  width: 100%;
  margin-top: 7px;
  justify-content: left;
}

.kjoretoy-add-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  align-items: baseline;
}

.kjoretoy-input-label {
  width: 200px;
  font-weight: bolder;
}

.oblat-info {
  margin: 5px;
  padding: 5px;
  padding: 5px;
  margin-bottom: 15px;
  font-size: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.kjoretoy-input:focus {
  border-color: #6e8095;
  outline: none;
}

.kjoretoy-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: white;
  border: 1px solid #c4c4c4;
  width: 100%;
}

.kjoretoy-input-section-table {
  display: block;
  max-width: 100%;
}
/*
.kjoretoy-body > .kjoretoy-row {
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  background: white;
  border: 1px solid #c4c4c4;
  width: 100%;
}
*/

.kjoretoy-button {
  height: 40px;
  padding: 0 20px;
  margin: 5px;
  margin-bottom: 15px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #6c757d;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;
}

.kjoretoy-button:disabled {
  cursor: progress;
}

.kjoretoy-input-section {
  /* display: flex;
  width: auto;
  flex-direction: row; */
  align-items: center;
  padding: 20px;
  margin: 10px 0;
  background-color: #d8d8d8;
}

.kjoretoy-input-section > .input-og-knapp {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.kjoretoy-input-section > input {
  margin: 0 5px;
}

.kjoretoy-input-section > button {
  margin: 0 5px;
}

.kjoretoy-input-filter-option {
  font-size: 1.2rem;
}

.kjoretoy-input-filter-option input[type="checkbox"] {
  transform: scale(1.3);
}
