.public-header {
  position: absolute;
  width: 100%;
  text-align: center;
  color: #4d4d4d;
  background: #d8d8d8;
  border-bottom: solid 3px #f5a623;
  display: flex;
  align-items: center;
}

.public-header h1 {
  font-weight: bold;
}

@media screen and (max-width: 969px) {
  .public-header h1 {
    font-size: 17px;
    margin: 1px;
    flex: 1;
  }

  .public-header img {
    width: 44px;
  }

  .App-logo-public {
    padding: 5px 10px 6px 4px;
    border-right: 1px solid #999;
  }
}

@media screen and (min-width: 969px) {
  .public-header img {
    height: 80px;
  }

  .public-header {
    display: block;
    padding: 20px;
  }
}
