.App-logo-spin {
  animation: App-logo-spin 1 20s linear;
  height: 80px;
}

.App-logo {
  height: 50px;
}

.App-header {
  position: relative;
  text-align: left;
  background-color: #222;
  padding: 45px 20px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-title {
  font-size: 1.5em;
  margin-left: 20px;
  margin-bottom: 0;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-navbar {
  position: absolute;
  top: 100px;
  right: 15px;
  font-size: 1.2em;
}

.App-navbar a {
  color: white;
  border-right: 1px solid white;
  margin-right: 10px;
  padding-right: 10px;
}

.App-navbar a:last-child {
  border: none;
}

.navlink-selected {
  text-decoration: underline;
  color: white;
}

.user {
  position: absolute;
  right: 40px;
}
