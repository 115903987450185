.oblatregistrering-page {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  justify-content: top;
  align-items: center;
}
.oblatregistrering-title {
  justify-content: stretch;
  width: 100%;
  padding: 15px 25px;
  line-height: 30px;
  font-size: 25px;
  font-weight: 300;
  color: #4d4d4d;
  text-align: center;
  background: #d8d8d8;
}

.oblatregistrering-add-form {
  padding: 15px;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #c4c4c4;
  width: 100%;
  margin-top: 7px;
  justify-content: left;
}

.oblatregistrering-add-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  align-items: baseline;
}

.oblattype-input-label {
  width: 200px;
  font-weight: bolder;
}

.oblattype-input {
  width: 250px;
  margin: 5px;
  padding: 5px;
  padding: 5px;
  height: 40px;
  margin-bottom: 15px;
  font-size: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.oblattype-input:focus {
  border-color: #6e8095;
  outline: none;
}

.oblatregistrering-container {
  background-color: #ccc;
  width: 100%;
  margin: 10px 0px;
}
.oblatregistrering-container > h3 {
  padding: 10px;
}

.oblatregistrering-body {
  padding: 15px;
  display: flex;
  flex-direction: row;
  /* flex: 1 1 auto; */
  background: white;
  border: 1px solid #c4c4c4;
  width: 100%;
}
.oblatregistrering-body > div {
  padding: 15px;
  border: 1px solid gray;
  width: 33%;
}
.oblatregistrering-body > div > button {
  width: 50%;
}
.oblatregistrering-body-submit {
}
/*
.oblatregistrering-body > .oblattype-row {
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  background: white;
  border: 1px solid #c4c4c4;
  width: 100%;
}
*/

.oblattype-button {
  height: 40px;
  padding: 0 20px;
  margin: 5px;
  margin-bottom: 15px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #6c757d;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;
}

.oblatregistrering-button:disabled {
  cursor: progress;
}
