.component-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  color: #4d4d4d;
  background: #d8d8d8;
}

.sidebar-container {
  background: #f3f3f3;
  height: 100%;
}

.sidebar-header {
  background: rgba(0, 0, 0, 0.05);
  color: #4d4d4d;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
}

.sidebar-header h2 {
  padding: 20px 20px 10px 20px;
}

.sidebar-header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-header-buttons {
  padding: 20px;
}

.container-fluid {
  background: #4d4d4d;
  padding: 30px;
}

.master-content {
  padding: 7px 30px;
  min-height: 100vh;
}

.master-header {
  padding: 30px 30px 15px 30px;
}

@media screen and (max-width: 969px) {
  .container-fluid {
    padding: 20px;
  }
}
