.konfigurasjon-page {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  justify-content: top;
  align-items: center;
}

.finn-konfigurasjon-page {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

.konfigurasjon-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: white;
  border: 1px solid #c4c4c4;
  width: 100%;
}

.konfigurasjon-edit-form-container {
  padding: 15px;
  background: white;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.konfigurasjon-edit-form-container + .konfigurasjon-edit-form-container {
  margin-top: 15px;
}
.konfigurasjon-edit-form {
  display: flex;
  flex-direction: row;

  justify-content: left;
  align-items: center;
  background: white;
  margin-top: 7px;
}
.konfigurasjon-edit-form > .konfigurasjon-label {
  padding: 15px;
  flex-basis: 300px;
  width: 250px;
  text-align: right;
}
.konfigurasjon-edit-form > a {
  padding: 10px;
}

.konfigurasjon-add-form {
  padding: 15px;
  display: flex;
  flex-direction: row;
  background: white;
  border: 1px solid #c4c4c4;
  width: 100%;
  margin-top: 7px;
}

h1.konfigurasjon-title {
  justify-content: stretch;
  width: 100%;
  padding: 15px 25px;
  line-height: 30px;
  font-size: 25px;
  font-weight: 300;
  color: #4d4d4d;
  text-align: center;
  background: #d8d8d8;
}

.konfigurasjon-input {
  width: 250px;
  margin: 5px;
  padding: 5px;
  padding: 5px;
  height: 40px;
  font-size: 15px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.konfigurasjon-input-dato {
  width: 250px;
}

.konfigurasjon-input:focus {
  border-color: #6e8095;
  outline: none;
}

.konfigurasjon-formatted-date {
  text-align: center;
  min-width: 150px;
  margin-left: 25px;
}

.konfigurasjon-button {
  height: 40px;
  padding: 0 20px;
  margin-left: 15px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #6c757d;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;
}

.konfigurasjon-button:disabled {
  cursor: progress;
}

.konfigurasjon-lost {
  text-align: center;
  margin-bottom: 0px;
}

.konfigurasjon-lost a {
  color: #666;
  text-decoration: none;
  font-size: 13px;
}

.konfigurasjon-info {
  margin-top: 15px;
}
