.fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in-out;
}
.toast-top-right {
  position: fixed;
  top: 15px;
  right: 15px;
  min-width: 500px;
}
