.bruker-detaljer {
  display: flex;
  width: 100%;
  padding: 15px 25px;
  color: #4d4d4d;
  background: #d8d8d8;
}

.kunde-view > span {
  display: block;
}
.kunde-view > span > b {
  margin-right: 3px;
}

.oblat-action-button {
  margin: 0 2px;
}

.brukere-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-top: 20px;
}

.brukere-section {
  margin-right: 10px;
  width: 100%;
}

.brukerinfo-section {
  width: 100%;
}
