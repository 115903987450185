.public-container {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.public-container-content {
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 20vh auto;
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width: 969px) {
  .public-container-content {
    padding: 0;
    margin: 8vh auto;
  }

  .public-container-content {
    font-size: 1.2rem;
  }

  .public-container-content h1 {
    font-size: 2.2rem;
  }
}
