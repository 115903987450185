.bruker-detaljer {
  display: flex;
  /* width: 100%; */
  padding: 15px 25px;
  color: #4d4d4d;
  background: #d8d8d8;
}

.kunde-view > span {
  display: block;
}
.kunde-view > span > b {
  margin-right: 3px;
}

.oblat-action-col {
  display: flex;
}

.oblat-action-button {
  flex: 1 0 0px;
  margin: 0 2px;
  max-width: 78px;
}

.kun-innevaerende-sesong-checkbox {
  height: 38px;
  padding-top: 18px;
  color: white;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.kun-innevaerende-sesong-checkbox input[type="checkbox"] {
  transform: scale(1.3);
}
